<template>
  <FormWrapper :is-loading="isLoading">
    <PageTitle title="Awards" slot="title" link="/helpContent/applyForLeave" />

    <ValidationObserver ref="validator">
      <FormRow>
        <div class="col-sm-3">
          <FormSelect
            label="Select Staff"
            :items="staffList"
            item-name="Name"
            item-value="id"
            v-model="form.staffId"
            rules="required"
          />
        </div>
        <div class="col-sm-3">
          <FormSelect
            label="Nominated By"
            :items="NominatedList"
            item-name="NAME"
            item-value="id"
            v-model="form.nominatedId"
            rules="required"
          />
        </div>
        <div class="col-sm-3">
          <FormSelect
            label="Award Type"
            :items="awardList"
            item-name="Name"
            item-value="id"
            v-model="form.awardId"
            rules="required"
          />
        </div>
        <div class="col-sm-3">
          <Year label="Year" v-model="form.year" rules="required" />
        </div>
      </FormRow>
      <FormRow>
        <div class="col-sm-3">
          <InputMonth
            label="Month"
            :items="monthsList"
            item-name="NAME"
            item-value="id"
            v-model="form.month"
            rules="required"
          />
        </div>
        <div class="col-sm-4">
          <InputTextArea
            label="Narration"
            v-model="form.narration"
            rules="required"
          />
        </div>
        <div class="col-sm-4">
          <InputFile
            :rules="{
              required: true,
              docCheck: 'jpg,jpeg,png,pdf',
              checkFileRegex: true,
            }"
            :value="form.file"
            label="Choose File"
            @onChange="uploadFileHandler"
          />
        </div>
      </FormRow>
    </ValidationObserver>
    <br />
    <FormRow>
      <div class="col-sm-6">
        <ButtonGroup>
          <FormButton
            type="info"
            @click="
              () => {
                $router.go(-1);
              }
            "
            >Back</FormButton
          >
          <FormButton type="success" @click="sendData()">Save</FormButton>
        </ButtonGroup>
      </div>
    </FormRow>
    <Popup
      title="Success"
      type="success"
      :value="showMessage.isVisible"
      @close="() => (showMessage.isVisible = false)"
      :closable="true"
    >
      {{ showMessage.message }}
      <div slot="footer">
        <div slot="footer">
          <FormButton
            @click="
              () => {
                $router.go(-1);
              }
            "
            >Ok</FormButton
          >
        </div>
      </div>
    </Popup>
  </FormWrapper>
</template>

<script>
import FormWrapper from "Components/form/FormWrapper";
import PageTitle from "Components/layout/PageTitle";
import loadingMixin from "Mixins/loadingMixin";
import Year from "../../components/Year";
import { getAllStaffByHRorManager, getAwardList, getMainUsers, staffAward } from "../api";
import { ValidationObserver } from "vee-validate";

export default {
  name: "add",
  mixins: [loadingMixin],
  components: {
    FormWrapper,
    PageTitle,
    ValidationObserver,
    Year,
  },
  data() {
    return {
      showMessage: {
        isVisible: false,
      },
      form: {
        staffId: null,
        year: "",
        month: "",
        awardId: null,
        narration: "",
        id: null,
        nominatedId: null,
        file: null,
      },
      monthsList: [],
      NominatedList: [],
      staffList: [],
      awardList: [],
      mainFolderName: ''
    };
  },
  computed: {
    userData() {
      return this.$store.state.user.user;
    },
  },
  watch:{},
  created() {
    this.getAllList();
  },
  methods: {
    uploadFileHandler(file) {
      this.form.file = file;
    },
    sendData() {
      this.$refs.validator.validate().then((result) => {
        if (result) {
          this.showMessage.isVisible = false;
          for (let i = 0; i < this.staffList.length; i++) {
            if (this.staffList[i].id === this.form.staffId) {
              this.mainFolderName = this.staffList[i].EmpCode + '_' + this.staffList[i].FirstName;
            }
          }
          const data = new FormData();
          data.append("staffId", this.form.staffId);
          data.append("year", this.form.year);
          data.append("month", this.form.month);
          data.append("awardId", this.form.awardId);
          data.append("narration", this.form.narration);
          data.append("id", this.form.id);
          data.append("nominatedId", this.form.nominatedId);
          data.append("file", this.form.file);
          data.append("mainFolderName", this.mainFolderName);
          staffAward(data).then((res) => {
            this.form = {};
            this.showMessage.isVisible = true;
            this.showMessage.message = res.data[0].message;
          });
        }
      });
    },
    async getAllList() {
      await getAllStaffByHRorManager().then((res) => {
        this.staffList = res.data;
      });
      getAwardList().then((res) => {
        this.awardList = res.data;
      });
      getMainUsers().then((res) => {
        this.NominatedList = res.data;
      });
    },
  },
};
</script>
<style lang="scss">
    //Mahima on 27-07-2023:Css fixes so that scrollbar won't disappear after submitting a form
    body{
        padding-right: 0px !important;
        overflow: auto !important;
    }
</style>