var render = function render(){var _vm=this,_c=_vm._self._c;return _c('FormWrapper',{attrs:{"is-loading":_vm.isLoading}},[_c('PageTitle',{attrs:{"slot":"title","title":"Awards","link":"/helpContent/applyForLeave"},slot:"title"}),_c('ValidationObserver',{ref:"validator"},[_c('FormRow',[_c('div',{staticClass:"col-sm-3"},[_c('FormSelect',{attrs:{"label":"Select Staff","items":_vm.staffList,"item-name":"Name","item-value":"id","rules":"required"},model:{value:(_vm.form.staffId),callback:function ($$v) {_vm.$set(_vm.form, "staffId", $$v)},expression:"form.staffId"}})],1),_c('div',{staticClass:"col-sm-3"},[_c('FormSelect',{attrs:{"label":"Nominated By","items":_vm.NominatedList,"item-name":"NAME","item-value":"id","rules":"required"},model:{value:(_vm.form.nominatedId),callback:function ($$v) {_vm.$set(_vm.form, "nominatedId", $$v)},expression:"form.nominatedId"}})],1),_c('div',{staticClass:"col-sm-3"},[_c('FormSelect',{attrs:{"label":"Award Type","items":_vm.awardList,"item-name":"Name","item-value":"id","rules":"required"},model:{value:(_vm.form.awardId),callback:function ($$v) {_vm.$set(_vm.form, "awardId", $$v)},expression:"form.awardId"}})],1),_c('div',{staticClass:"col-sm-3"},[_c('Year',{attrs:{"label":"Year","rules":"required"},model:{value:(_vm.form.year),callback:function ($$v) {_vm.$set(_vm.form, "year", $$v)},expression:"form.year"}})],1)]),_c('FormRow',[_c('div',{staticClass:"col-sm-3"},[_c('InputMonth',{attrs:{"label":"Month","items":_vm.monthsList,"item-name":"NAME","item-value":"id","rules":"required"},model:{value:(_vm.form.month),callback:function ($$v) {_vm.$set(_vm.form, "month", $$v)},expression:"form.month"}})],1),_c('div',{staticClass:"col-sm-4"},[_c('InputTextArea',{attrs:{"label":"Narration","rules":"required"},model:{value:(_vm.form.narration),callback:function ($$v) {_vm.$set(_vm.form, "narration", $$v)},expression:"form.narration"}})],1),_c('div',{staticClass:"col-sm-4"},[_c('InputFile',{attrs:{"rules":{
            required: true,
            docCheck: 'jpg,jpeg,png,pdf',
            checkFileRegex: true,
          },"value":_vm.form.file,"label":"Choose File"},on:{"onChange":_vm.uploadFileHandler}})],1)])],1),_c('br'),_c('FormRow',[_c('div',{staticClass:"col-sm-6"},[_c('ButtonGroup',[_c('FormButton',{attrs:{"type":"info"},on:{"click":() => {
              _vm.$router.go(-1);
            }}},[_vm._v("Back")]),_c('FormButton',{attrs:{"type":"success"},on:{"click":function($event){return _vm.sendData()}}},[_vm._v("Save")])],1)],1)]),_c('Popup',{attrs:{"title":"Success","type":"success","value":_vm.showMessage.isVisible,"closable":true},on:{"close":() => (_vm.showMessage.isVisible = false)}},[_vm._v(" "+_vm._s(_vm.showMessage.message)+" "),_c('div',{attrs:{"slot":"footer"},slot:"footer"},[_c('div',{attrs:{"slot":"footer"},slot:"footer"},[_c('FormButton',{on:{"click":() => {
              _vm.$router.go(-1);
            }}},[_vm._v("Ok")])],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }